import router from '../router/index'

const validationRules = {
    required: v => !!v || router.app.$t('validation.required'),
    length30: v => v == null ||  v == '' || (v.length <= 30) || router.app.$t('validation.max30'),
    length50: v => v == null ||  v == '' || (v.length <= 50) || router.app.$t('validation.max50'),
    length100: v => v == null ||  v == '' || (v.length <= 100) || router.app.$t('validation.max100'),
    length300: v => v == null ||  v == '' || (v.length <= 300) || router.app.$t('validation.max300'),
    length600: v => v == null ||  v == '' || (v.length <= 600) || router.app.$t('validation.max600'),
    length3000: v => v == null ||  v == '' || (v.length <= 3000) || router.app.$t('validation.max3000'),
    pinteger:  v => Number.isInteger(Number(v)) && v >= 0 || router.app.$t('validation.integer'),
    phone: v => {
        const pattern = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
        return v == null ||  v == '' || pattern.test(v) || router.app.$t('validation.phone')
    },
    pfloat:  v => !Number.isNaN(Number.parseFloat(v))  && v >= 0 || router.app.$t('validation.float'),
    email: v => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return v == null ||  v == '' || pattern.test(v) || router.app.$t('validation.email')
    },
    files: (v) => v == null || v.size < 2000000 || router.app.$t('validation.image_size'),
    url: v => {
            var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
            return v == null ||  v == '' || pattern.test(v) || router.app.$t('validation.url');
    }
}

export {
    validationRules
}