<template>
  <div class="mb-12 mx-8">
    <div class="py-8 my-16 mx-auto"
    style="max-width: 800px; border-radius: 25px; background: rgba(242, 112, 156, 0.1)">
      <v-row v-if="getDisplaySize() !== 'xs'">
        <v-col cols="3">
          <v-img
              max-height="150px"
              src="@/assets/pink-check.svg"
              contain
              rounded
              class="rounded mx-8 mt-4 float-left"
          >
          </v-img>
        </v-col>
        <v-col class="text-left">
          <div class="px-12" style="max-width: 550px">
            <h2 class="mt-4 mb-2"
                style="font-size: 30px">
              <span style="font-weight: 300"> {{ $t('eventform.message_title') }} </span>
              {{ $t('eventform.message_title_bold') }}
            </h2>
            <p class="body-2">{{ $t('eventform.message_subtitle') }}</p>
          </div>

        </v-col>
      </v-row>

      <v-row v-if="getDisplaySize() === 'xs'" class="text-center mx-auto py-8" style="max-width: 320px">
          <v-img
              max-height="150px"
              src="@/assets/pink-check.svg"
              contain
              rounded
              class="rounded mx-8 mt-4 float-left"
          >
          </v-img>

          <div class="px-12" style="max-width: 550px">
            <h2 class="mt-4 mb-2"
                style="font-size: 30px">
              <span style="font-weight: 300"> {{ $t('eventform.message_title') }} </span>
              {{ $t('eventform.message_title_bold') }}
            </h2>
            <p class="body-2">{{ $t('eventform.message_subtitle') }}</p>
          </div>
        </v-row>
    </div>


    <div style="max-width: 500px" class="mx-auto pb-16 px-10">
    <h2 align="center"
        class="mt-16 mb-2"
        style="font-size: 30px">
      <span style="font-weight: 300"> {{$t('eventform.form_header')}} </span>
      {{$t('eventform.form_header_bold')}}
    </h2>
    <p class="body-1 text-center mb-8">{{$t('eventform.form_text')}}</p>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="submitForm"
    >
      <v-text-field
          v-model="form.name"
          :rules="[rules.required, rules.length30]"
          counter
          :label="$t('contact_consultant.name')"
          required
          filled
          rounded
          class="mt-0"
      ></v-text-field>
      <v-textarea
          v-model="form.message"
          :label="$t('contact_consultant.description')"
          :rules="[rules.required, rules.length300]"
          filled
          counter
          rounded
      ></v-textarea>
      <v-text-field
          v-model="form.email"
          :rules="[rules.required, rules.email]"
          :label="$t('contact_consultant.email')"
          type="email"
          :hint="$t('contact_consultant.email_hint')"
          required
          filled
          rounded
      ></v-text-field>
      <v-btn
          class="mr-4 mt-4 create-event-btn"
          type="submit"
          :disabled="!valid"
          color="secondary"
          elevation="0"
          rounded
          block
          large
      >
        {{ $t('eventform.send_short') }}
      </v-btn>
    </v-form>
      </div>

    <v-snackbar
        v-model="snackbar"
        :timeout=5000
        :color=snackbarcolor
    >
      <h3 v-html="response"></h3>
      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>


  </div>
</template>

<script>
import axios from 'axios';
import * as config from '@/api/config';
import {validationRules} from '@/utils/validation_rules';
import {generateFormData} from '@/utils/helpers';

export default {
  data() {
    return {
      snackbar: false,
      snackbarcolor: "green",
      valid: true,
      form: {
        name: '',
        email: '',
        message: '',
      },
      rules: validationRules,
      response: '',
    }
  },
  name: "EventConfirmation",
  props: ['eventData', 'imgSrc'],
  methods: {
    getDisplaySize(){
      return this.$vuetify.breakpoint.name;
    },

    validate () {
      return this.$refs.form.validate()
    },

    submitForm() {
      // this.form = removeHtml(this.form)
      if(this.validate())
      {
        const formData = generateFormData(this.form)
        this.snackbarcolor = 'success';
        axios.post(config.KUMSCHO_API_SERVER + '/mail/contact', formData).then(response => {
          this.displayResponse(response.data)
        }).catch(error => {
          let msg = error.message ? error.message : error.response.statusText
          this.displayResponse('Error: ' + msg)
        }).finally(() => {
          this.snackbar = true
        });
      }
    },
    displayResponse(res) {
      if (res == 'Ok') {
        res = 'Das E-Mail wurde gesendet'
        this.$refs.form.reset()
      }
      else this.snackbarcolor = 'error';
      this.response = res;
    }
  },
  mounted() {
    window.analytics.track('Visited Event Confirmation Page');
  }
}
</script>

<style scoped>

</style>